import { useState, useEffect } from "react"
import axios from "axios"

const Home = () => {
  // Sample pending transactions
  const [transactions, setTransactions] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchTransactions = async () => {
      const response = await axios
        .get("https://us-central1-cheap-naira.cloudfunctions.net/api/admin/")
        .catch(console.error)

      setTransactions(response.data)
      setLoading(false)
    }

    fetchTransactions()
  }, [])

  const markAsComplete = async (id, uid) => {
    setLoading(true)

    await axios
      .post(
        "https://us-central1-cheap-naira.cloudfunctions.net/api/admin/approve",
        {
          paymentID: id,
          uid,
        }
      )
      .catch((e) => {
        console.error(e?.data?.message || e?.response?.data?.message)

        return // abort the operation
      })

    const filteredResult = transactions.filter((transaction) => {
      return transaction.paymentID !== id
    })

    setTransactions(filteredResult)
    setLoading(false)
  }

  return loading ? (
    <h1>Loading...</h1>
  ) : (
    <div className="container mx-auto p-4">
      <div className="max-w-l ">
        <h1 className="text-2xl font-bold mb-4">Pending Transactions</h1>
        <div className="space-y-4">
          {transactions.length === 0 ? (
            <p className="text-gray-600 italic">No data</p>
          ) : (
            transactions.map((transaction) => {
              const keys = Object.keys(transaction)

              return (
                <div className="rounded-lg shadow-md max-w-md mx-auto p-6 ">
                  <ul>
                    {keys.map((key) => {
                      if (typeof transaction[key] === "object") return ""
                      return (
                        <li key={key}>
                          <strong>{key}:</strong> {transaction[key]}
                        </li>
                      )
                    })}
                  </ul>
                  <div className="space-x-2">
                    <button
                      onClick={() =>
                        markAsComplete(transaction.paymentID, transaction.uid)
                      }
                      className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                    >
                      Mark as Complete
                    </button>
                  </div>
                </div>
              )
            })
          )}
        </div>
      </div>
    </div>
  )
}

export default Home
